<template>
  <VContainer>
    <VRow>
      <VCol>
        <TTBreadcrumbs
          :items="breadcrumbs"
          data-test-label="track-breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="5">
        <VImg
          data-test="track-image"
          :aspect-ratio="16/9"
          class="rounded-lg"
          src="https://loremflickr.com/416/260"
        />
      </VCol>
      <VCol cols="7">
        <VRow>
          <VCol>
            <div
              class="tt-text-title-1"
              data-test="track-title"
            >
              Адаптация сотрудников
            </div>
          </VCol>
          <!-- TODO DELETE Для тестов вкладки когда noData-->
          <VCol cols="auto">
            <TTBtn @click="emptyStateToggle">
              Empty state
            </TTBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <div
              class="tt-text-body-2"
              data-test="track-description"
            >
              Общая адаптация для всех сотрудников, про компанию, документы, локацию, офис, акционеров и вообще всё тут
            </div>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VList class="pa-0">
              <VListItem
                class="px-0"
                data-test="track-creator"
              >
                <VListItemAvatar>
                  <VAvatar
                    color="tt-light-yellow"
                    data-test="track-creator-avatar"
                  >
                    AD
                  </VAvatar>
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle data-test="track-creator-name">
                    Юлия Гилимьянова
                  </VListItemTitle>
                  <VListItemSubtitle
                    class="tt-text-caption tt-light-mono-64--text"
                    data-test="track-creator-date-change"
                  >
                    Создатель. Дата создания: 09.09.2022
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
              <VListItem
                class="px-0"
                data-test="track-creator"
              >
                <VListItemAvatar>
                  <VAvatar
                    color="tt-light-blue"
                    data-test="track-creator-avatar"
                  >
                    AD
                  </VAvatar>
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle data-test="track-creator-name">
                    Эдуард Москаленко
                  </VListItemTitle>
                  <VListItemSubtitle
                    class="tt-text-caption tt-light-mono-64--text"
                    data-test="track-creator-date-change"
                  >
                    Последнее изменение: вчера в 12:59
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TabsWrapper
          v-model="selectedTab"
          :tabs="tabs"
          underline="toolbar"
          data-test="tabs"
        >
          <VTabsItems v-model="selectedTab">
            <VTabItem
              v-for="tab in tabs"
              :key="tab.text"
              :data-test="`tab-content-` + tab.value"
              :transition="false"
              :value="tab.value"
            >
              <VContainer class="px-0">
                <template v-if="emptyState">
                  <EmptySection
                    v-if="tab.value==='content'"
                    text="Здесь появятся уровни, которые можно будет наполнить задачами."
                    title="Создайте уровни"
                  >
                    <template #image>
                      <VRow justify="center">
                        <VCol cols="auto">
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton
                                :prepend-icon="TRACK_TYPES_ICON[TRACK_TYPES.LEVEL]"
                                :prepend-icon-color="TRACK_ICON_COLOR"
                              />
                            </VCol>
                          </VRow>
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton
                                class="ml-10"
                                :prepend-icon="TASK_TYPE_ICONS[TASK_TYPES.READ]"
                                :prepend-icon-color="TASK_TYPE_ICON_COLOR"
                              />
                            </VCol>
                          </VRow>
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton
                                :prepend-icon="CONTENT_TYPE_ICONS[CONTENT_TYPES.HTML]"
                                :prepend-icon-color="CONTENT_TYPE_ICON_COLOR"
                              />
                            </VCol>
                          </VRow>
                        </VCol>
                      </VRow>
                    </template>
                    <template #optional>
                      <TTBtn
                        text
                        data-test-label="empty-section-button-track-edit"
                        @click="openEditModal"
                      >
                        Перейти в редактирование
                      </TTBtn>
                    </template>
                  </EmptySection>
                  <EmptySection
                    v-if="tab.value==='appointment'"
                    title="Выберите, кому назначить трек"
                    text="С помощью поиска и фильтров найдите нужных сотрудников."
                  >
                    <template #image>
                      <VRow justify="center">
                        <VCol cols="auto">
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton>
                                <template #append>
                                  <TTAvatarGroup
                                    :items="userAvatars"
                                    x-small
                                  />
                                </template>
                              </ListItemSkeleton>
                            </VCol>
                          </VRow>
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton
                                class="ml-10"
                              >
                                <template #append>
                                  <TTAvatarGroup
                                    :items="userAvatars"
                                    x-small
                                  />
                                </template>
                              </ListItemSkeleton>
                            </VCol>
                          </VRow>
                          <VRow dense>
                            <VCol cols="12">
                              <ListItemSkeleton>
                                <template #append>
                                  <TTAvatarGroup
                                    :items="userAvatars"
                                    x-small
                                  />
                                </template>
                              </ListItemSkeleton>
                            </VCol>
                          </VRow>
                        </VCol>
                      </VRow>
                    </template>
                  </EmptySection>
                </template>
                <template v-if="!emptyState">
                  <template v-if="tab.value==='content'">
                    <OLevelList @click:add-button="showContentForm" />
                  </template>
                  <template v-if="tab.value==='appointment'">
                    <VContainer class="px-0">
                      <VRow>
                        <VCol>
                          <div
                            class="px-0 tt-text-caption tt-light-mono-46--text"
                            data-test="subheader"
                          >
                            Выбранные группы
                          </div>
                        </VCol>
                      </VRow>
                      <VRow no-gutters>
                        <VCol>
                          <VList
                            class="px-0"
                            data-test="groups"
                          >
                            <template v-for="(group,index) in appointmentGroups">
                              <VListItem
                                :key="index"
                                class="px-0"
                                data-test="group-item"
                                @click="showNotification"
                              >
                                <VListItemContent>
                                  <VListItemTitle
                                    class="tt-text-body-2"
                                    data-test="group-title"
                                  >
                                    {{ group.title }}
                                  </VListItemTitle>
                                  <VListItemSubtitle
                                    class="tt-text-body-2 tt-light-mono-46--text"
                                    data-test="group-subtitle"
                                  >
                                    {{ $tc('employee', group.count) }}
                                  </VListItemSubtitle>
                                </VListItemContent>
                              </VListItem>
                              <VDivider
                                v-if="index+1!==appointmentGroups.length"
                                :key="`divider-${index}`"
                              />
                            </template>
                          </VList>
                        </VCol>
                      </VRow>
                    </VContainer>
                  </template>
                </template>
              </VContainer>
            </VTabItem>
          </VTabsItems>
        </TabsWrapper>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

import TabsWrapper from '@/components/shared/TabsWrapper.vue';
import EmptySection from '@/components/shared/EmptySection.vue';
import ListItemSkeleton from '@/components/skeletons/ListItemSkeleton.vue';
import { CONTENT_TYPE_ICON_COLOR, CONTENT_TYPE_ICONS, CONTENT_TYPES } from '@/constants/contentTypes';
import { TASK_TYPE_ICON_COLOR, TASK_TYPE_ICONS, TASK_TYPES } from '@/constants/taskTypes';
import { TRACK_ICON_COLOR, TRACK_TYPES, TRACK_TYPES_ICON } from '@/constants/trackTypes';
import OLevelList from '@/components/level/OLevelList.vue';

export default {
  name: 'TrackId',
  components: {
    OLevelList,
    ListItemSkeleton,
    EmptySection,
    TabsWrapper,
  },
  inject: ['Names'],
  data() {
    return {
      CONTENT_TYPES,
      CONTENT_TYPE_ICONS,
      CONTENT_TYPE_ICON_COLOR,
      TASK_TYPE_ICON_COLOR,
      TASK_TYPE_ICONS,
      TASK_TYPES,
      TRACK_TYPES,
      TRACK_TYPES_ICON,
      TRACK_ICON_COLOR,
      selectedTab: '',
      // TODO DELETE Для тестов вкладки когда noData
      emptyState: false,
      tabs: [
        {
          text: 'Содержание',
          value: 'content',
        }, {
          text: 'Назначения',
          value: 'appointment',
        }],
      userAvatars: [{
        src: 'https://loremflickr.com/22/22',
        text: 'First',
        icon: '',
      }, {
        src: 'https://loremflickr.com/22/22',
        text: 'Second',
        icon: '',
      }, {
        src: 'https://loremflickr.com/22/22',
        text: 'Third',
        icon: '',
      }],
      appointmentGroups: [
        {
          title: 'Бухгалтеры',
          count: 14,
        },
        {
          title: 'Маркетологи',
          count: 24,
        }],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          to: { name: this.Names.R_TRACKS },
          text: 'Треки',
        },
        {
          to: {
            name: this.Names.R_TRACKS,
            query: { type: 'published' },
          },
          text: 'Опубликованные',
        },
        {
          to: {
            name: this.Names.R_TRACK,
            params: { id: this.$route.params.id },
          },
          text: 'Название трека',
        },
      ];
    },
  },
  mounted() {
    this.initFormEditListener();
  },
  beforeDestroy() {
    this.destroyFormListener();
  },
  methods: {
    // TODO DELETE Для тестов вкладки когда noData
    emptyStateToggle() {
      this.emptyState = !this.emptyState;
    },
    initFormEditListener() {
      this.$root.$on('click:open-track-edit', this.redirectToTrackManagement);
    },
    redirectToTrackManagement() {
      this.$router.push({ name: this.Names.R_TRACK_MANAGEMENT, params: { id: this.$route.params.id } });
    },
    destroyFormListener() {
      this.$root.$off('click:open-track-edit');
    },
    openEditModal() {
      // Здесь будет в дальнейшем вызов формы редактирования трэка или переедет в боковую панель
      // TODO DELETE
      this.$root.$emit('showNotifier', {
        title: 'Представь будто форма редактирования открылась',
        timeout: 6000,
        type: 'success',
      });
    },
    showNotification() {
      // TODO DELETE Просто заглушка для удобства
      this.$root.$emit('showNotifier', {
        title: 'Будет редирект на сраницу с группой',
        timeout: 6000,
        type: 'success',
      });
    },
    showContentForm() {
      // Здесь будет в дальнейшем вызов формы редактирования уровня
      // TODO DELETE
      this.$root.$emit('showNotifier', {
        title: 'Сделаем вид, что открылась форма добавления и все работает',
        timeout: 6000,
        type: 'success',
      });
    },
  },
};
</script>

<style scoped>

</style>
